<template>
    <v-col cols="12">
        <div class="text-left mb-3">
            <v-btn color="black" text depressed @click="back">
                Vrati se na poruke
            </v-btn>
        </div>
        <v-row class="primer rounder mb-8 pa-4">
            <v-row>
                <v-col cols="6" class="text-left">
                    {{ message.type_title }}
                </v-col>
                <v-col cols="6" class="text-right">
                    <span>{{ message.date_created | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY. HH:mm') }}</span>
                </v-col>
                <v-col cols="12" class="text-left">
                    {{ message.subject }}
                </v-col>
                <v-col cols="12" class="text-left">
                    <div class="white_bgd pa-3" v-html="message.comment">
                    </div>
                </v-col>
                <v-col cols="12" class="text-right py-0">
                    {{message.user}}
                </v-col>
            </v-row>
        </v-row>
        <v-row class="mx-2">
            <v-col :class="(one.source)?'message-blue':(one.status==3?'message-orange':'message-orange-new')" v-for="one in message.replies" :key="one.id" cols="11" :offset="(!one.source)?1:0">
                <v-row>
                    <v-col cols="12" class="text-left" v-html="one.comment">
                    </v-col>
                    <v-col cols="6" class="text-left user_signature">
                        {{one.date_created | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY. HH:mm')}}
                    </v-col>
                    <v-col cols="6" class="text-right user_signature">
                        {{one.user}}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div class="text-center my-4">
            <v-btn class="primer" depressed @click="openMessageReplyModal">
                Nova poruka
            </v-btn>
        </div>
        <div class="text-left mb-3">
            <v-btn color="black" text depressed @click="back">
                Vrati se na poruke
            </v-btn>
        </div>
        <v-dialog
          v-model="MessageReplyModal"
          max-width="320"
        >
          <v-card>
            <v-card-title class="headline">
                Dodaj poruku
                <v-icon small color="gray" @click="closeMessageReplyModal">fas fa-times</v-icon>
            </v-card-title>
            <v-card-text max-height="300">
                <form @submit.prevent="submit" :id="form_id">
                    <v-textarea
                        outlined
                        v-model="message_text"
                        :error-messages="message_textErrors"
                        label="Vaša poruka"
                        required
                        @input="$v.message_text.$touch()"
                        @blur="$v.message_text.$touch()"
                    ></v-textarea>
                </form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="primer"
                @click="send_message()"
                :disabled="submitStatus === 'PENDING'"
              >
                Pošalji poruku
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-col>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { helpers, required, maxLength } from 'vuelidate/lib/validators'
const character_map = helpers.regex('character_map', /^[a-zA-Z0-9Ć-ćČ-čĐ-đŠ-šŽ-ž\-_':;.,()!?+ \s]*$/)
export default {
    data: () => ({
        form_id: 'messageReplyModal',
        form_enabled: false,
        message_text:''
    }),
    props: ['id'],
    computed: {
        ...mapGetters({
            one_message: 'messages/message_by_id',
            submitStatus: 'forms/submitStatus',
            submitError: 'forms/submitError',
            user: 'user/getUser',
            MessageReplyModal: 'forms/MessageReplyModal'
        }),
        message () {
            let message = this.one_message(this.$route.params.id)
            return message
        },
        message_textErrors () {
            const errors = []
            if (!this.$v.message_text.$dirty) return errors
            !this.$v.message_text.maxLength && errors.push('Maksimum 500 znakova')
            !this.$v.message_text.character_map && errors.push('Poruka zadrži nedozvoljeni znak')
            !this.$v.message_text.required && errors.push('Poruka je obavezna')
            return errors
        },
    },
    mixins: [validationMixin],
    validations: {
        message_text: {
            required,
            character_map,
            maxLength: maxLength(500),
        }
    },
    methods: {
        ...mapActions('forms',[
            'openMessageReplyModal',
            'closeMessageReplyModal',
        ]),
        ...mapActions('messages',[
            'changeMessageStatus'
        ]),
        send_message(){
            var formPass = false
            this.$v.$touch()
            formPass = this.$v.$invalid
            if (formPass) {
                this.$store.dispatch('forms/changeSubmitStatus','ERROR')
            } else {
                this.$store.dispatch('forms/changeSubmitStatus','PENDING')
                let formData = {
                    comment: this.message_text,
                    user_id: this.user.id,
                    message_id: this.message.id,
                    source:1
                }
                this.$store.dispatch('forms/submitForm', { form: this.form_id, data: formData })

            }
        },
        back(){
            this.$router.go(-1)
        },
    },
    beforeCreate(){
        if(typeof this.message == 'undefined')
            this.$store.dispatch('messages/get_message_by_id', this.$route.params.id)
        if(this.$store.getters['user/getCredentials'].reklamacije == 0) this.$router.push({ name: 'not_auth' })
    },
    beforeUpdate(){
        //this.$store.dispatch('products/getProductDetails', this.$route.params.id)
    },
    mounted(){
        let t = this
        setTimeout((t)=>{
            if(t.message.status<3) t.$store.dispatch('messages/changeMessageStatus', {message_id:t.message.id, status:3})
            if(typeof t.message.replies != 'undefined')
                t.message.replies.forEach((el) => {
                    if(el.status<3) t.$store.dispatch('messages/changeReplyStatus', {reply_id: el.id, status:3, message_id:t.message.id})
                })
        }, 5000, t)
    },
    updated(){
        let t = this
        setTimeout((t)=>{
            if(typeof t.message.replies != 'undefined')
                t.message.replies.forEach((el) => {
                    if(el.status<3) t.$store.dispatch('messages/changeReplyStatus', {reply_id: el.id, status:3, message_id:t.message.id})
                })
        }, 5000, t)
    },
    destroyed(){
        this.$store.dispatch('forms/changeSubmitStatus',null)
        this.$store.dispatch('forms/changeSubmitStatus',null)
        this.$store.dispatch('forms/changeSubmitError',null)
    }
};
</script>
<style scoped>
    .text-left .v-input--selection-controls{
        margin-top:0px !important
    }
    .headline{
        position:relative
    }
    .headline button{
        position:absolute;
        right:10px;
        top:10px;
        font-size: 18px;
    }
    .v-btn:not(.v-btn--round).v-size--default{
        min-width:150px;
    }
    .user_signature{
        padding-top:0;
        padding-bottom:0;
        font-size:12px;
        font-weight: 300;
        color:rgba(125, 125, 125, .5);
        line-height: 17px;
    }
    .product_holder{
        position:relative;
        width:100%;
        min-height:80px;
        line-height:20px;
        padding:10px 10px 10px 80px;
    }
    .product_img{
        position:absolute;
        left:0px;
        top:5px;
        width:70px;
        height:70px;
    }
    .product_title{
        min-height:20px;
        line-height:20px;
        position:relative;
    }
    .product_title span{
        position: absolute;
        right: 0;
        text-align: right;
        display: block;
        top: 0;
    }
    .blur{
        position:relative;
    }
    .text_left{
        position: relative;
    }
    .back{
        position:absolute;
        width:26px;
        height:26px;
        left:13px;
        top:13px;
    }
    .blur::after{
        content:"";
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background: rgba(239,239,239,.5);
    }
    .message-blue, .message-orange, .message-orange-new {
        position: relative;
        margin-bottom: 10px;
        padding: 0 10px;
        background-color: #A8DDFD;
        text-align: left;
        border: 1px solid #97C6E3;
        border-radius: 10px;
    }

    .message-orange {
        position: relative;
        background-color: #F2DFD7;
        border: 1px solid #dfc2b7;
    }
    .message-orange-new{
        background-color: #f0b196;
        border: 1px solid #e4a48c;
    }
    .message-blue:after, .message-orange:after, .message-orange-new:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 15px solid #A8DDFD;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        top: 0;
        left: -15px;
    }

    .message-blue:before, .message-orange:before, .message-orange-new:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 17px solid #97C6E3;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        top: -1px;
        left: -17px;
    }

    .message-orange:after {
        border-top: 15px solid #F2DFD7;
        left: unset;
        right: -15px;
    }

    .message-orange:before {
        border-top: 17px solid #dfc2b7;
        left: unset;
        right: -17px;
    }
    .message-orange-new:after {
        border-top: 15px solid #f0b196;
        left: unset;
        right: -15px;
    }

    .message-orange-new:before {
        border-top: 17px solid #e4a48c;
        left: unset;
        right: -17px;
    }
</style>>